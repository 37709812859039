
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import Row from '@/components/toolkit/details/row/row.vue'
  import { fixPrice } from '@/utils/general'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Payment } from '@/entities/finance'
  import { SaleOrderView } from '@/components/forms/view/SaleOrderView'
  import { SaleOrder } from '@/entities/sales'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import dayjs from 'dayjs'
  import { plainToInstance } from 'class-transformer'
  import { Deal } from '@/entities/crm'

@Component({
  components: { GAlert, GDatePicker, GFiles, GRadioButton, GFormSlot, Row, FormTitle },
  methods: { fixPrice },
})
  export default class validationPaymentComponent extends SaleOrderView {
  @Prop({ type: Boolean }) value: boolean | undefined
  @Prop({ type: Boolean, default: false }) supervisor: boolean | undefined
  @Prop({ type: Object, default: () => null }) payment!: Payment | null
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: true }) isSale: boolean
  @Prop({ type: String, default: 'Pago' }) title: string
  @Prop({ type: Object, default: () => null }) saleOrder: SaleOrder | null

  declare $refs: {
    form: HTMLFormElement
    payment: GFormSlot
  };

  alert = {
    open: false,
    title: '',
  }

  status = {
    paid: null,
    toUpdate: null,
    pending: null,
    successfull: null,
  }

  formData = {
    purchaseBackup: [],
    exist: null,
    paymentProof: [],
    confirm: [],
    date: null,
    comment: '',
    letterApproval: [],
  }

  closed = null
  paymentProofMessage = null
  radioButtonMessage = ''
  loading = false
  paymentType = null
  paymentFileProperties = []
  filesPropertiesConfirm = {
    accept: '',
    multiple: false,
    fileTypeId: null,
    name: '',
    label: '',
    required: false,
    icon: '',
  }

  async mounted () {
    this.closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    this.status.paid = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'paid' } } }] },
    })

    this.status.toUpdate = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'to_update' } } }] },
    })

    this.status.pending = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'pending_payment' } } }] },
    })

    this.status.successfull = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'successful' } } },
          { status: { process: { table_name: { _eq: 'payment' } } } },
        ],
      },
    })

    this.paymentFileProperties = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }] },
    })

    const file = this.paymentFileProperties?.find(file => file.name === 'payment_confirmation')

    if (file) {
      this.filesPropertiesConfirm = {
        accept: file.fileType.mimes,
        multiple: file.multiple,
        fileTypeId: file.fileType.id,
        name: file.name,
        label: file.description,
        required: file.required,
        icon: file.fileType.icon,
      }
    }
  }

  closePayment () {
    this.$emit('input', false)
  }

  async send () {
    const {
      formData: { exist, comment, paymentProof, confirm, letterApproval },
      filesPropertiesConfirm,
      filesProcess,
      payment,
      displayFile,
      paymentTypeFinancing,
      paymentTypeVehiclePartially,
      isSale,
    } = this

    if (exist === null) {
      this.radioButtonMessage = 'Este campo es requerido'
      return
    }

    if (!exist && !comment?.length) {
      this.$refs.payment.validate()
      return
    }

    if (exist && (!confirm.length && displayFile) && isSale) {
      this.$refs.payment.validate()
      return
    }
    this.loading = true
    if (isSale && !paymentTypeFinancing) {
      const status = exist ? this.status.successfull[0].id : this.status.toUpdate[0].id

      await this.updatePaymentStatus(payment, exist, status, comment)
    } else if (isSale && paymentTypeFinancing) {
      await this.pushData({
        model: 'Payment',
        fields: {
          id: payment.id,
          id_process_status: this.status.pending[0].id,
          id_validator: this.idEmployee,
        },
      })
    } else if (!isSale && paymentTypeVehiclePartially) {
      if (await this.handleVehiclePartially(payment, exist, comment) === false) return
    } else {
      await this.handlePaymentPurchase(exist, payment, comment)
    }

    if (exist && isSale && !paymentTypeFinancing) {
      await this.handleFileType(confirm, { properties: filesPropertiesConfirm }, filesProcess.payment, payment.id)
    }

    if (paymentProof?.length) {
      await this.insertFileProcessInfo({
        id: paymentProof[0].id,
        comment: !exist ? comment : '',
        valid: exist,
        validation_type: 'manual',
      })
    }

    if (letterApproval?.length) {
      await this.insertFileProcessInfo({
        id: letterApproval[0].id,
        comment: !exist ? comment : '',
        valid: exist,
        validation_type: 'manual',
      })
    }
    this.closePayment()
  }

  async handlePaymentPurchase (exist, payment, comment) {
    const lead = this.backup?.negotiation?.inspection?.appraisal?.deal?.lead
    await this.pushData({
      model: 'Payment',
      fields: {
        id: payment.id,
        id_process_status: exist ? this.status.pending[0].id : this.status.toUpdate[0].id,
        id_validator: this.idEmployee,
        comment,
      },
    })
    if (!exist) {
      const purchase = await this.fetchData({
        query: { name: 'find', model: 'PurchaseOrder' },
        filter: { negotiation: { inspection: { appraisal: { deal: { appraisals: { deal: { id_lead: { _eq: lead.id } } } } } } } },
      })

      const status = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'purchase_order' } } }, { status: { name: { _eq: 'to_update' } } }] },
      })
      await this.pushData({
        model: 'PurchaseOrder',
        fields: {
          id: purchase[0].id,
          id_process_status: status?.[0].id,
        },
      })
    }
  }

  async updatePurchaseOrder (payment, comment, lead) {
    await this.pushData({
      model: 'Payment',
      fields: {
        id: payment.id,
        id_process_status: this.status.toUpdate[0].id,
        id_validator: this.idEmployee,
        comment,
      },
    })
    const purchase = await this.fetchData({
      query: { name: 'find', model: 'PurchaseOrder' },
      filter: { negotiation: { inspection: { appraisal: { deal: { appraisals: { deal: { id_lead: { _eq: lead.id } } } } } } } },
    })

    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'purchase_order' } } }, { status: { name: { _eq: 'to_update' } } }] },
    })
    await this.pushData({
      model: 'PurchaseOrder',
      fields: {
        id: purchase[0].id,
        id_process_status: status?.[0].id,
      },
    })
  }

  async handleVehiclePartially (payment, exist, comment) {
    const lead = this.backup?.negotiation?.inspection?.appraisal?.deal?.lead
    if (!exist) {
      await this.updatePurchaseOrder(payment, comment, lead)
      this.closePayment()
      return false
    }

    const customLead = await this.getLeadById(lead.id)
    const sale = customLead.deals?.map(deal => plainToInstance(Deal, deal))?.find(deal => deal.isSale)

    const saleOrders = await this.getStockSalesById(sale.stock.id)

    if (saleOrders?.length && !saleOrders?.some(saleOrder => saleOrder.id === sale?.order?.id)) {
      this.alert.open = true
      this.alert.title = `El vehículo ya se encuentra en la venta ${saleOrders.map(sale => sale.id)}`
      return false
    }

    await this.updateSellAndPayments(sale.order, sale, payment)
  }

  confirmAction () {
    this.alert.open = false
    this.alert.title = ''
  }

  async updatePaymentStatus (payment, exist, status, comment) {
    let fields
    if (!exist) {
      fields = {
        id: payment.id,
        id_process_status: status,
        id_validator: this.idEmployee,
        comment,
      }
    } else {
      fields = {
        id: payment.id,
        id_process_status: this.closed[0].id,
        id_closing_reason: status,
        id_validator: this.idEmployee,
        comment,
      }
    }

    await this.pushData({
      model: 'Payment',
      fields,
    })
  }

  async updateSellAndPayments (saleOrder, deal, payment) {
    const statusActive = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    await this.pushData(({
      model: 'SaleOrder',
      fields: {
        id: saleOrder.id,
        id_process_status: statusActive[0].id,
      },
    }))

    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ status: { name: { _eq: 'closed' } } }, { process: { table_name: { _eq: 'payment' } } }] },
    })

    const close = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: { _and: [{ type: { name: { _eq: 'successful' } } }, { status: { process: { table_name: { _eq: 'payment' } } } }] },
    })

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })
    const config = process?.[0]?.config?.renewal

    const financialAccount = await this.fetchData({
      query: { name: 'find', model: 'FinancialAccount' },
      filter: { id: { _eq: config.id_financial_account_spent } },
      force: true,
    })

    const fields = {
      id: payment.id,
      id_process_status: status[0].id,
      id_closing_reason: close[0].id,
      id_recipient_account: financialAccount?.[0].personAccount?.id,
    }

    await this.pushData({ model: 'Payment', fields })

    // insert payment
    const processValue = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
    })
    const paymentType = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
      filter: { name: { _eq: 'vehicle_partially_paid' } },
    })

    const fieldsPayment = {
      id_payment_type: paymentType[0].id,
      id_process: processValue[0].id,
      id_process_record: saleOrder.id,
      id_process_status: status[0].id,
      id_closing_reason: close[0].id,
      amount: payment.amount,
      date: dayjs().format('MM-DD-YYYY'),
      id_payer: payment.paymentRecipient.person.id,
      id_deal: deal.id,
      id_financial_account: config.id_financial_account_income,
    }

    await this.pushData({ model: 'Payment', fields: fieldsPayment })

    await this.updateStock(deal)
  }

  async updateStock (deal) {
    const { stock: { id } } = deal
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ status: { name: { _eq: 'selling' } } }, { process: { table_name: { _eq: 'stock' } } }] },
    })

    const fields = {
      id,
      id_process_status: status[0].id,
    }

    await this.pushData({ model: 'Stock', fields })
    await this.publishStock(id)
  }

  @Watch('formData.exist', { immediate: true })
  onExistChange (newValue: boolean | null) {
    if (newValue === null) return
    this.radioButtonMessage = ''
  }

  get paymentAndProcess () {
    const { payment, filesProcess } = this

    return { payment, filesProcess }
  }

  @Watch('paymentAndProcess', { immediate: true, deep: true })
  async onProcessTrafficTicketChange ({ payment, filesProcess }) {
    if (!payment?.id || !filesProcess?.payment) return

    if (payment.id && this.isSale && !this.paymentTypeFinancing) {
      this.formData.date = payment.dateFormatted

      const files = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: payment.id } },
            { parameter: { process: { id: { _eq: filesProcess.payment } } } },
          ],
        },
        force: true,
      })

      this.formData.paymentProof = files.filter(file => file.parameter.name === 'payment_receipt')
      this.formData.confirm = files.filter(file => file.parameter.name === 'payment_confirmation')
      this.formData.exist = this.formData.paymentProof[0].valid
    } else if (payment.id && this.isSale && this.paymentTypeFinancing) {
      const files = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: this.saleOrder.financing.evaluation.evaluation.id } },
            { parameter: { process: { id: { _eq: filesProcess.evaluation } } } },
          ],
        },
        force: true,
      })
      const filesPayment = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: payment.id } },
            { parameter: { process: { id: { _eq: filesProcess.payment } } } },
          ],
        },
        force: true,
      })
      this.formData.confirm = filesPayment.filter(file => file.parameter.name === 'payment_confirmation')
      this.formData.letterApproval = files.filter(file => file.parameter.name === 'approval_letter')
      this.formData.exist = this.formData.letterApproval[0].valid
    } else {
      const files = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: payment.id } },
            { parameter: { process: { id: { _eq: filesProcess.payment } } } },
          ],
        },
        force: true,
      })

      this.formData.purchaseBackup = files?.filter(file => file.parameter.name === 'spending_support') || []
    }
  }

  get displayFile () {
    const { payment: { type }, payment } = this

    let otherTypes = []
    if (!payment?.paymentRecipient) {
      otherTypes = ['debit_card', 'credit_card', 'bank_deposit', 'electronic_transfer', 'financing']
    }

    const types = [...otherTypes]

    return types.includes(type.name)
  }

  get disabledFields () {
    return this.paymentIsClosed
  }

  get paymentName () {
    const { isSale } = this

    return isSale ? 'Pagador' : 'Recibe'
  }

  get labelPay () {
    const { isSale } = this

    return isSale ? '¿Válida pago?' : '¿Autoriza gasto?'
  }

  get personName () {
    const { isSale, payment } = this

    return isSale ? payment.payer.fullName : payment?.paymentRecipient?.person.fullName
  }

  get personUid () {
    const { isSale, payment } = this

    return isSale ? payment.payer?.uid : payment?.paymentRecipient?.person?.uid
  }

  get paymentTypeFinancing () {
    const { payment: { type } } = this

    return type.name === 'financing'
  }

  get paymentTypeVehiclePartially () {
    const { payment: { type } } = this

    return type.name === 'vehicle_partially_paid'
  }

  get paymentIsClosed () {
    return this.payment?.status.isClosed
  }
  }
